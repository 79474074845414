import React, { useState } from "react";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import Section5 from "../../pageLayout/DataEngineering/Section5";
import Trendingsection from "./Trendingsection";
import AccoladesAndRecognitions from "../../pageLayout/HomePageLayout/AccoladesAndRecognitions";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SchemaDataArchtect } from "../BlogSchema";
import { Helmet } from "react-helmet";



const Data1 = [
  {
    id: 1,
    heading: "About Aays",
    content:
      "Aays is a fast-growing analytics consulting firm solving some of the most complex corporate finance challenges faced by large and rapidly scaling companies. Using big data, cloud computing, and AI/ML, we transform corporate finance operations for our clients. As an employee-first company, we believe that happy employees lead to happy customers, creating a successful business. Join us for a life-changing journey. ",
  },
  {
    id: 2,
    heading: "Your team",
    content:
      "As a key member of the consulting team, you will help clients re-invent their corporate finance functions by leveraging advanced analytics. You’ll work closely with senior stakeholders to design and implement data strategies in the finance domain, including use cases like controllership, FP&A, and GPO. Your role will include delivering scalable technical solutions using cloud and big data technologies. Collaboration with Business Consultants and Product Owners will be critical to ensure effective solutions. Strong communication and organizational skills are essential for success. ",
  },
];
const Data2 = [
  {
    id: 1,
    heading: "Responsibilities in the Data Architect Role ",
    data: [
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Design and Architecture:</span>  Create and manage end-to-end data and analytics solution architecture from concept to delivery. 
     </>
        )
      },


      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Data Modeling:</span> Design, develop, and support conceptual, logical, and physical data models for analytics solutions. 
     </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Industry Standards:</span> Ensure alignment with industry-accepted data architecture principles, standards, and guidelines. 
     </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Cloud Expertise:</span>  Lead the design, sizing, and setup of Azure environments and related services. 
         </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Mentorship:</span> Provide mentoring on data architecture design and requirements to development and business teams.
     </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Solution Reviews:</span> Review solution requirements and architectures to ensure optimal resource use and system integration.
     </>
        ),
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Technology Trends:</span> Advise on new technology trends and adoption strategies for maintaining competitive advantage.
     </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Thought Leadership:</span>  Participate in pre-sales activities and contribute to publishing thought leadership content.
     </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Technology Strategy: </span>  Collaborate with founders to drive the organization’s technology strategy. 
     </>
        )
      },
      {
        content: (
          <>
         <span style={{ fontWeight: 700 }}>Recruitment: </span> Lead recruitment efforts for technology roles in data analytics. 
     </>
        )
      },
    ],
  },
  {
    id: 2,
    heading: "Skills and Experience ",
    data: [
      {
        content: "Proven experience delivering multiple data solutions. ",
      },
      {
        content:
          "Expertise in ETL development using tools like SSIS, Data Factory, and other Microsoft technologies. ",
      },
      {
        content:
          "In-depth knowledge of SQL Server, Azure Synapse, Azure Databricks, HD Insights, and Azure Data Lake",
      },
      {
        content:
          "Familiarity with various data models, including normalized, de-normalized, star, and snowflake models. ",
      },
      {
        content:
          "Experience with transactional, temporal, time series, structured, and unstructured data. ",
      },
      {
        content:
          "Proficiency in Data Quality Management and standardization tools like Microsoft DQS. ",
      },
      {
        content:
          "Strong understanding of operational dependencies, including VMs, Networking, VPN, Active Directory, and Storage in both on-premise and cloud environments. ",
      },
      {
        content:
          "Advanced knowledge of computer science and software engineering principles, including OOP, Visual Studio, SDKs, PowerShell, and CLI. ",
      },
      {
        content:
          "Hands-on coding expertise in Spark, Python, SQL, and ability to adapt to new languages. ",
      },
      {
        content:
          "Familiarity with Microsoft and Azure DevOps and Automation practices",
      },
    ],
  },
  {
    id: 3,
    heading: "Why Data Architect Role at Aays? ",
    data: [
      {
        content:
          "Work on cutting-edge technology in data analytics and cloud computing",
      },
      {
        content:
          "Collaborate with senior stakeholders on impactful projects.  ",
      }, {
        content:
          "Drive innovation in corporate finance through advanced analytics. ",
      },
      {
        content:
          "Be part of a supportive, employee-first company culture.  ",
      },
    ]
  }
];

const data = [
  //   {
  //     text: <ChevronLeftIcon />,
     
  //  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]

function DataArchitect() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div id="homePage" className="openPosition-box dataArchitectMobile" >
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( SchemaDataArchtect)}
        </script>
         </Helmet>

      <Navbar />
      <GoogleAnalytics />

      <HeaderSection text1=" Join as a Data Architect" picture={banner} button={true} />

      <div className="position-main">
        <div className="position-body">
          <div className="new-box-seventy">

            <div className="back-button">
              {data.map((ele, index) => (
                <div style={{ display: 'flex' }}>
                  <ChevronLeftIcon />
                  <a href={ele.url} className="content" key={index}>   
                    {ele.text} 
                  </a>
                   {index !== data.length - 1} 
                </div>
              ))}
            </div>

            <div className="top">
              <div className="heading">
                <h4>
                <span>
                  <b>Job description</b>
                </span>
                </h4>
              </div>
              <div className="position">
                <h4>
                <span>Position: Data Architect</span>
                </h4>
              </div>
              <div className="desired">
                <h4>
                <span>Desired experience: 6+ years</span>
                </h4>
              </div>
            </div>
            <hr
              style={{
                height: "5px",
                backgroundColor: "#bb1ccc",
                border: "none",
              }}
            />

            {Data1.map((item) => (
              <>
                <div className="heading1">
                  <div className="heading">
                    <span>
                      <h3>
                      <b>{item.heading}</b></h3>
                    </span>
                  </div>
                  <div className="content" >{item.content}</div>
                </div>
              </>
            ))}

            <div className="heading2-list">
              {Data2.map((item) => (
                <>
                  <div className="heading">
                    <h3>
                    <span>{item.heading}</span>
                    </h3>
                  </div>
                  <div className="list">
                    <ul>
                      {item.data.map((item1) => (
                        <li>{item1.content}</li>
                      ))}
                    </ul>
                  </div>
                </>
              ))}
            </div>
            <p style={{color:'white',margin:'3px 0'}}>Ready to redefine corporate finance with Aays? Join us as a Data Architect and make an impact. </p>
            <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
            </div>
           
    </div>
        

        </div>
       

      </div>
      <Section5 showFlag={true} techHeading = "Our AI Excellence" secheading="Trusted Tech Partners" />
     <AccoladesAndRecognitions className="home-main" />
      <Trendingsection />
     


      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Data Architect" />

      <Footer />
    </div>
  );
}

export default DataArchitect;
