import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import StickyButtons from "../pageLayout/BlogLayout/StickyButtons";
import Section2 from "../pageLayout/BlogLayout/Section2";
import Section3 from "../pageLayout/BlogLayout/Section3";
import Section4 from "../pageLayout/BlogLayout/Section4";
import Section5 from "../pageLayout/BlogLayout/Section5";
import CustomerSuccessSection from "../pageLayout/AboutPageLayout/Section5";
import Footer from "../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../pageLayout/BlogLayout/Blog.scss";
import Navbar from "../nav/NavBar";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/blog-banner-right.png";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import AiAndGenerativeAI from "../pageLayout/BlogLayout/AiAndGenerativeAI";
import BottomBanner from "../reuseLayout/BottomBanner";
import LifeAays from "../pageLayout/BlogLayout/LifeAays";
import { BlogSchema } from "./BlogSchema";
import { Helmet } from "react-helmet";

const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + 700);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - 700);
};

function Blog() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div id="homePage" className="blogspage-cls">
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( BlogSchema)}
    </script>
</Helmet>
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Explore Our Latest Blog Posts"
          subtext1="Dive into a Wealth of Knowledge"
          subtext2="on Industry Trends, Tips, AI and Innovation"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Explore Our Latest Blog Posts"
          subtext1="Dive into a Wealth of Knowledge"
          subtext2="on Industry Trends, Tips, AI and Innovation"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Explore Our Latest Blog Posts"
          subtext1="Dive into a Wealth of Knowledge"
          subtext2="on Industry Trends, Tips, AI and Innovation"
          picture={mobileBannerImage}
        />
      )}

      <MiddleBanner text="Our employees sharing their unique perspectives and valuable learnings, offering an insider's view into the world of analytics." />

      <div>
        {!isMobile && !isIpad && <StickyButtons />}
        <Section5 />
        <AiAndGenerativeAI />

        <Section2 />
        <Section3 />
        <Section4 />
        <LifeAays />
      </div>

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="blog"
            show="true"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="blog"
            show="true"
          />
        )}
        <BottomBanner contentChange={true} />
        <Footer />
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default Blog;
