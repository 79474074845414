import React from 'react';
import { Helmet } from 'react-helmet';


export const HomeSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    // "dateModified": "November 21, 2024",
    // "datePublished": "November 8, 2024",
    "headline": "Aays | Your AI & Analytics Solutions Partner",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/"
    },
    "description": " Explore AI-driven solutions for finance optimization, supply chain efficiency and ESG initiatives. Here's how our AI technologies deliver $2Bn business impact.",
    "url": "https://www.aaysanalytics.com/",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const BlogSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Insights & Innovations | Aays Blog",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/Blog",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/Blog"
    },
    "description": "Stay updated with the latest insights on AI, Data Engineering, and business transformation. Explore Aays blogs for trends, tips, and innovations.",
    "url": "https://www.aaysanalytics.com/Blog",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const AboutUsSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "About Aays | Transforming Businesses with AI and Data",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/about",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/about"
    },
    "description": "Learn about Aays, a recognised leader in AI and Data Engineering. Discover how we empower businesses with innovative AI solutions and a business-first approach.",
    "url": "https://www.aaysanalytics.com/about",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const CareerSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Join Our Team at Aays | Careers in AI and Data",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/career",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/career"
    },
    "description": "Explore exciting career opportunities at Aays, an AI and Data Engineering powerhouse. Join a team driven by innovation and transform businesses through cutting-edge technology.",
    "url": "https://www.aaysanalytics.com/career",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const NewsroomSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Newsroom | Aays - Latest News and Updates",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/newsroom",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/newsroom"
    },
    "description": "Get the latest news, updates, and press releases from Aays. Discover how we're transforming industries with AI, data science, and ML solutions.",
    "url": "https://www.aaysanalytics.com/newsroom",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const ContactSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Contact Us | Aays - Get in touch with our AI & Data Experts",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/contact",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/contact"
    },
    "description": "Reach out to Aays for inquiries, consultations, or support. Our team is here to assist you with AI, data science, and machine learning solutions tailored to your needs.",
    "url": "https://www.aaysanalytics.com/contact",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaCustomerSuccessStory ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Aays | Customer Success Stories",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/customer-success-stories",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/customer-success-stories"
    },
    "description": "Explore transformative analytics in supply chain, commercial finance, and operations. Witness the power of data-driven solutions through impactful case studies.",
    "url": "https://www.aaysanalytics.com/customer-success-stories",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaPrivacyPolicy ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Privacy Policy | Aays",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/privacyPolicy",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/privacyPolicy"
    },
    "description": "Read the privacy policy of Aays to understand how we protect your data and ensure transparency in our processes. Your privacy is our priority.",
    "url": "https://www.aaysanalytics.com/privacyPolicy",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaDataengineering ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Aays | Data Engineering and AI Solutions",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-and-data-engineering",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-and-data-engineering"
    },
    "description": "Empower your organization with Aays' data engineering and AI solutions. We have delivered ~ 2 PB of data engineering projects in complex data landscape.",
    "url": "https://www.aaysanalytics.com/ai-and-data-engineering",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaAinnovations ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "AI Innovations and Enterprise AI| Aays",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-innovations-enterprise-solutions",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-innovations-enterprise-solutions"
    },
    "description": "Explore AI innovations and enterprise AI solutions with our advanced ML and data science strategies, driving impactful results.",
    "url": "https://www.aaysanalytics.com/ai-innovations-enterprise-solutions",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaManufacturing ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "AI/ML Services for Manufacturing | Aays",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-and-analytics-in-manufacturing",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-and-analytics-in-manufacturing"
    },
    "description": "Aays AI Solutions for manufacturing. Leverage AI/ML services, advanced analytics, and AI tools to optimize production, and drive innovation in manufacturing.",
    "url": "https://www.aaysanalytics.com/ai-and-analytics-in-manufacturing",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaFoodAndBeverage ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "AI Solutions for F&B Companies | Aays",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-solutions-for-food-and-beverage-companies",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-solutions-for-food-and-beverage-companies"
    },
    "description": "Discover Aays' AI and Gen AI solutions for F&B companies, driving innovation and efficiency. AI/ML for innovation in Food and beverage industry.",
    "url": "https://www.aaysanalytics.com/ai-solutions-for-food-and-beverage-companies",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaFinance ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Finance Analytics and AI Solutions | Aays ",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-and-finance-analytics",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-and-finance-analytics"
    },
    "description": "AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools.",
    "url": "https://www.aaysanalytics.com/ai-and-finance-analytics",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}
export const SchemaAutomotive = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Aays AI Solutions: Transformation in Automotive Industry with AI and Analytics",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-and-analytics-in-automotive-industry",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-and-analytics-in-automotive-industry"
    },
    "description": "Discover Aays' enterprise AI capabilities for the automotive industry. Leverage AI in automotive to harness telematics analytics, vehicle data insights and more.",
    "url": "https://www.aaysanalytics.com/ai-and-analytics-in-automotive-industry",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}
export const SchemaRetail = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "AI/ML for Retail: Inventory Management and Supply Chain| Aays",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/ai-solutions-retail",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/ai-solutions-retail"
    },
    "description": "Aays' AI solutions for retail. Inventory management, AI-powered supply chains, optimized customer journey. AI/ML for retail innovation.",
    "url": "https://www.aaysanalytics.com/ai-solutions-retail",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const AadiSchema ={
    "@context": "https://schema.org",
    "@type": "WebPage",
    "author": {
      "@type": "Organization"
    },
    "headline": "Aays Decision Intelligence | Finance Co-Pilot",
    "mainEntityOfPage": {
      "@id": "https://www.aaysanalytics.com/aays-decision-intelligence-aadi",
      "@type": "itemPage",
      "url": "https://www.aaysanalytics.com/aays-decision-intelligence-aadi"
    },
    "description": "Discover AaDI, Decision Intelligence Co-Pilot for Finance. Leverage AI-driven workflows to perform root-cause analysis, flux reporting, anomaly detection and more.",
    "url": "https://www.aaysanalytics.com/aays-decision-intelligence-aadi",
    "publisher": {
      "@type": "Organization",
      "name": "Aays",
      "url": "https://www.aaysanalytics.com/"
    }
}

export const SchemaConsumerPackagegood ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "AI/ML Solutions by Aays | CPG",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/ai-solutions-cpg",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/ai-solutions-cpg"
  },
  "description": " Advanced AI and analytics solutions for CPG industries. Enhance operations and decision-making with Aays's AI/ML capabilities for CPG businesses",
  "url": "https://www.aaysanalytics.com/ai-solutions-cpg",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaSupplyChain ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Aays | Supply Chain Analytics Solutions",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/supply-chain-analytics",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/supply-chain-analytics"
  },
  "description": " Explore AI-led supply chain analytics solutions offering demand planning, inventory management, cost analysis, AI resiliency tools, and sustainable practices. ",
  "url": "https://www.aaysanalytics.com/supply-chain-analytics",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaMarketmix ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Marketing Mix Strategy Optimization | Snack Foods Manufacturer Case Study ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/market-mix-modelling-snacks-food-manufacturers",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/market-mix-modelling-snacks-food-manufacturers"
  },
  "description": " A leading snack foods manufacturer optimized marketing mix strategies. Explore the impact of Market Mix Modeling on revenue generation and marketing investments.",
  "url": "https://www.aaysanalytics.com/customer-success-stories/market-mix-modelling-snacks-food-manufacturers",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaVendorPayment ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "AI-led Vendor Payments Solutions | Case Study ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/vendor-payments-optimization-case-study",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/vendor-payments-optimization-case-study"
  },
  "description": "Explore how Aays used AI algorithms to optimize vendor payments, reducing early payments and inconsistencies, driving $200M cash optimization through analytics.",
  "url": "https://www.aaysanalytics.com/customer-success-stories/vendor-payments-optimization-case-study",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaVendorRiskManagement ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Efficient Vendor Risk Management | Consumer Goods Case Study  ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/vendor-risk-management-case-study",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/vendor-risk-management-case-study"
  },
  "description": "A global CPG company efficiently managed vendor risks, finding 40,000 vendor risk profiles, ensuring proactive risk mitigation strategies and cost savings of $2Mn. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/vendor-risk-management-case-study",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaInnovationAnlaytic ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "CPG Innovation Analytics: Value Assessment & New Product Impact | Aays  ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/analytics-to-assess-impact-of-innovation",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/analytics-to-assess-impact-of-innovation"
  },
  "description": "Discover how advanced analytics empowered a CPG conglomerate to assess innovation value and measure the impact of new product launches. Explore data-driven insights. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/analytics-to-assess-impact-of-innovation",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaAitoolController ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "AI-Powered Finance Controllership | Aays  ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/ai-powered-tool-for-finance-controllers",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/ai-powered-tool-for-finance-controllers"
  },
  "description": " Explore how Aays' AI-powered tools revolutionized finance controllership, enabling faster book closure, precise anomaly detection and risk management. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/ai-powered-tool-for-finance-controllers",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaPrimeCost ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Enhanced Prime Cost Visibility: CPG Success Story | Aays",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/prime-cost-visibility-global-CPG-success-story",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/prime-cost-visibility-global-CPG-success-story"
  },
  "description": " Explore how our prime cost visibility empowered a global CPG company to make informed pricing decisions, optimize raw material spending, and boost profitability.  ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/prime-cost-visibility-global-CPG-success-story",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaInventoryAnalytics ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Enhanced CPG Inventory Management | Aays",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/inventory-optimization-global-CPG",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/inventory-optimization-global-CPG"
  },
  "description": " Aays optimized inventory management for a global CPG, enhancing visibility, and demand prioritization. Learn about stock analysis and expiring stock strategies. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/inventory-optimization-global-CPG",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}


export const SchemaWorkingCapital ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Enhanced Working Capital Efficiency for Global CPG | Aays",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/working-capital-analytics-global-CPG",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/working-capital-analytics-global-CPG"
  },
  "description": "Aays improved working capital efficiency for a global CPG. Explore insights on accounts receivable, inventory, and profits leading to enhanced financial success. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/working-capital-analytics-global-CPG",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const Schemasupplycustomer ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Customer Success: Strengthening Supply Chain with Demand Forecasting | Aays",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting"
  },
  "description": "Discover how Aays enhanced supply chain operations through advanced demand forecasting and supply planning solutions, delivering measurable results. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}



export const Schemadailysales ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Advanced Daily Sales Reporting Techniques | CPG Case Study",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/advanced-daily-sales-positioning-case-study",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/advanced-daily-sales-positioning-case-study",
  },
  "description": "Advanced smoothening and forecasting techniques revolutionized daily sales reporting for a global CPG. Witness improved data trust, governance over trade expenditures. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/advanced-daily-sales-positioning-case-study",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}




export const SchemaAccountreciveable ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Strengthened Accounts Receivable for a Global CPG",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
  },
  "description": "Learn how Aays improved accounts receivable for a global CPG, reducing DSO by 15 days, boosting working capital, and enhancing financial efficiency. ",
  "url": "https://www.aaysanalytics.com/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}


export const SchemaDataArchtect ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Data Architect Role | Join Aays and Redefine Corporate Finance",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/careers/data-architect-role",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/careers/data-architect-role",
  },
  "description": "Explore an exciting opportunity as a Data Architect at Aays. Design innovative data solutions using big data, cloud computing, and AI/ML to solve complex corporate finance problems ",
  "url": "https://www.aaysanalytics.com/careers/data-architect-role",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}


export const SchemaDataEngneeringsection ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Data Engineer Job at Aays | Apply Now",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/careers/data-engineer-job",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/careers/data-engineer-job",
  },
  "description": " Explore an exciting Data Engineer job at Aays. Work with cutting-edge big data and analytics technologies, grow your career, and solve complex business problems. ",
  "url": "https://www.aaysanalytics.com/careers/data-engineer-job",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}


export const SchemaDataSciencejob ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "Data Scientist Job at Aays | Apply Now ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/careers/data-scientist-job",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/careers/data-scientist-job",
  },
  "description": "Explore an exciting Data Scientist job at Aays. Collaborate with experts, leverage advanced analytics, and fast-track your career in a dynamic environment.  ",
  "url": "https://www.aaysanalytics.com/careers/data-scientist-job",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}

export const SchemaConsumerFood ={
  "@context": "https://schema.org",
  "@type": "WebPage",
  "author": {
    "@type": "Organization"
  },
  "headline": "CPG Supply Chain Optimization and Efficiency ",
  "mainEntityOfPage": {
    "@id": "https://www.aaysanalytics.com/blogs/data-analytics-in-consumer-packaged-goods",
    "@type": "itemPage",
    "url": "https://www.aaysanalytics.com/blogs/data-analytics-in-consumer-packaged-goods",
  },
  "description": "Discover how data-driven analytics can revolutionize CPG supply chains, reduce costs, and improve efficiency. Learn about demand forecasting, inventory management, and spend analytics.  ",
  "url": "https://www.aaysanalytics.com/blogs/data-analytics-in-consumer-packaged-goods",
  "publisher": {
    "@type": "Organization",
    "name": "Aays",
    "url": "https://www.aaysanalytics.com/"
  }
}



























































