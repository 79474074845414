import React from "react";
import Dataengpagelogo1 from "../../assets/img/Dataeng-page-logo-1.png";
import Dataengpagelogo2 from "../../assets/img/Dataeng-page-logo-2.png";
import Dataengpagelogo3 from "../../assets/img/Dataeng-page-logo-3.png";
import Dataengpagelogo4 from "../../assets/img/Dataeng-page-logo-1 (4).png";
import Dataengpagelogo5 from "../../assets/img/Dataeng-page-logo-1 (5).png";
import Dataengpagelogo6 from "../../assets/img/Dataeng-page-logo-1 (6).png";
import Dataengpagelogo7 from "../../assets/img/Dataeng-page-logo-1 (7).png";
import Dataengpagelogo8 from "../../assets/img/Dataeng-page-logo-1 (8).png";
import Dataengpagelogo9 from "../../assets/img/Dataeng-page-logo-1 (9).png";
import Dataengpagelogo10 from "../../assets/img/Dataeng-page-logo-1 (10).png";
import Dataengpagelogo11 from "../../assets/img/Dataeng-page-logo-1 (11).png";
import Dataengpagelogo12 from "../../assets/img/Dataeng-page-logo-1 (12).png";
import Dataengpagelogo13 from "../../assets/img/Dataeng-page-logo-1 (13).png";
import Dataengpagelogo14 from "../../assets/img/Dataeng-page-logo-1 (14).png";
import Dataengpagelogo15 from "../../assets/img/Dataeng-page-logo-1 (15).png";
import Dataengpagelogo16 from "../../assets/img/Dataeng-page-logo-1 (16).png";
import Dataengpagelogo17 from "../../assets/img/Dataeng-page-logo-1 (17).png";
import Dataengpagelogo18 from "../../assets/img/Dataeng-page-logo-1 (18).png";
import Dataengpagelogo21 from "../../assets/img/Dataeng-page-logo-1 (21).png";
import Dataengpagelogo22 from "../../assets/img/Dataeng-page-logo-1 (22).png";
import Dataengpagelogo23 from "../../assets/img/Dataeng-page-logo-1 (23).png";
import Dataengpagelogo24 from "../../assets/img/Dataeng-page-logo-1 (24).png";
import Dataengpagelogo25 from "../../assets/img/Dataeng-page-logo-1 (25).png";
import Dataengpagelogo26 from "../../assets/img/Dataeng-page-logo-1 (26).png";
import Dataengpagelogo27 from "../../assets/img/Dataeng-page-logo-1 (27).png";
import Dataengpagelogo28 from "../../assets/img/Dataeng-page-logo-1 (28).png";
import Dataengpagelogo29 from "../../assets/img/Dataeng-page-logo-1 (29).png";
import Dataengpagelogo30 from "../../assets/img/Dataeng-page-logo-1 (30).png";

const Section5 = ({ showFlag, techHeading ,secheading}) => {
  return (
    <div className="secondpage_section5-main  ">
      <div className="margin-box">
        {!showFlag && <span
          className="secondpage_section5-main-header"
          style={{
            width: "90%",
            margin: "auto",
            display: "block",
            fontSize: "30px",
          }}
        >
          Leading the Way with Our Tech Competency
        </span>}

        {!showFlag && <div style={{ width: "90%", margin: "auto", display: "block" }}>
          <div className="secondpage_section5  ">
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "140px" }}
                src={Dataengpagelogo3}
                alt="logo3"
              />
            </div>

            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "140px" }}
                src={Dataengpagelogo30}
                alt="logo30"
              />
            </div>

            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo4}
                alt="logo4"
              />
            </div>

            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "140px" }}
                src={Dataengpagelogo5}
                alt="logo5"
              />
            </div>

            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo1}
                alt="logo1"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo2}
                alt="logo2"
              />
            </div>

            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo6}
                alt="logo6"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo7}
                alt="logo7"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo8}
                alt="logo8"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo9}
                alt="logo9"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo10}
                alt="logo10"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo11}
                alt="logo11"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo12}
                alt="logo12"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo13}
                alt="logo13"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo14}
                alt="logo14"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo15}
                alt="logo15"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo16}
                alt="logo16"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo17}
                alt="logo17"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo18}
                alt="logo18"
              />
            </div>
          </div>
        </div>}

        {/* // our ai stack */}
        <span
          className="secondpage_section5-main-header"
          style={{
            width: "90%",
            margin: "auto",
            display: "block",
            fontSize: "30px",
          }}
        >
          {techHeading}
        </span>
        <div style={{ width: "90%", margin: "auto", display: "block" }}>
          <div className="secondpage_section5  ">
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "130px" }}
                src={Dataengpagelogo21}
                alt="logo21"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "150px" }}
                src={Dataengpagelogo22}
                alt="logo22"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "150px" }}
                src={Dataengpagelogo23}
                alt="logo23"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo24}
                alt="logo24"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo25}
                alt="logo25"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo26}
                alt="logo26"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo27}
                alt="logo27"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo28}
                alt="logo28"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo29}
                alt="logo29"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "100px" }}
                src={Dataengpagelogo30}
                alt="logo30"
              />
            </div>
          </div>
        </div>

        {/* // out tech partner  */}
        <span
          className="secondpage_section5-main-header"
          style={{
            width: "90%",
            margin: "auto",
            display: "block",
            fontSize: "30px",
          }}
        >
         {secheading}
        </span>
        <div style={{ width: "90%", margin: "auto", display: "block" }}>
          <div className="secondpage_section5  ">
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "120px" }}
                src={Dataengpagelogo29}
                alt="logo29"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "80px" }}
                src={Dataengpagelogo1}
                alt="logo1"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "120px" }}
                src={Dataengpagelogo2}
                alt="logo2"
              />
            </div>
            <div className="secondpage_section5-items">
              <img
                loading="lazy"
                style={{ width: "120px" }}
                src={Dataengpagelogo3}
                alt="logo3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
