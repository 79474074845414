import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import designElement from "../../assets/img/careerPageDesignElement.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Section5(props) {
  const dataFilter = props.data;
  const tag = props.tag;
  const visible = props?.show || "true";

  const filteredData = dataFilter.filter((item) => item.tags.includes(tag));

  return (
    <div className="about-section5">
      <div className="grid-box-container">
        <div className="left-container">
          <div className="background-design">
            <img loading="lazy" src={designElement} alt="Background-img" />
          </div>
          <div className="left">
            <div className="heading-text">{props.heading1 || "Customer"}</div>
            <div className="heading-text">{props.heading2 || "Success Stories"}</div>
          </div>
        </div>

        <div className="right">
          <Carousel
            className="about-customer-success"
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            infinite={true}
            slidesToSlide={1}
            swipeable={false}
            draggable={false}
            showDots={true}
          >
            {filteredData.map((data, index) => {
              return (
                <div className="box" key={index}>
                  <div className="box-heading">{data.subHeaderText}</div>

                  <p className="box-content">{data.content}</p>

                  {visible === "true" && (
                    <a href={`${data.redirectUrl}`}>
                      <div className="read-more-btn">
                        <button style={{ cursor: "pointer" }}>Read More</button>
                      </div>
                    </a>
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Section5;
