import React, {useState} from "react";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

const expertise = {
  heading: "Your expertise",
  data: [
    {
      content:
        "Good Understanding and experience of working in a complex, high octane environment especially working with senior and international stakeholders",
    },
    {
      content:
        "Prior management consulting experience with Big 4s or Big 3s or technology consulting firms or corporate finance experience with large multinational is preferred. Ability to provide thought leadership in corporate finance matters is preferred",
    },
    {
      content:
        "A minimum of 3-5 years of consulting experience in Data Analytics or finance domain",
    },
    {
      content:
        "Exhibit strong project management skills, with experience delivering end-to-end analytics/business transformation projects",
    },
    {
      content:
        "Excellent communication and interpersonal skills, demonstrating a comfort level collaborating with individuals outside of the organisation, with a passion for nurturing junior talent",
    },
    {
      content:
        "MBA or equivalent from a tier-1 business school with a minimum 3-5 years of post MBA experience in relevant industry",
    },
  ],
};

const Responsibilities = [
  {
    id: 1,
    heading: "Foundational",
    data: [
      {
        content:
          "Working as part of the consulting team to uncover deeper and more meaningful insights for our clients and provide more quantifiable predictions for the performance and business benefits of our solutions and recommendations",
      },
      {
        content:
          "Manage the day-to-day execution & delivery of Insight & Analytics projects that provide actionable insights to drive strategic decisions in the finance and controllership space. Harnessing a range of research and analytical techniques to answer key business questions",
      },
      {
        content:
          "Structure, analyse, and synthesise data to generate actionable outcomes, and work with senior leadership to develop impactful insights to answer client strategic business questions",
      },
      {
        content:
          "Being a trusted advisor to client stakeholders, and building strong relationship with internal teams & external partners",
      },
      {
        content:
          "Support in the standardisation and improvement of existing workflows and processes, and the development of practical, relevant and innovative new Insights & Analytics products",
      },
      {
        content:
          "Be a role model for junior team members and a strong advocate for Insights & Analytics across the business",
      },
    ],
  },
  {
    id: 2,
    heading: "Building Relationships & Guiding Team Growth",
    data: [
      {
        content:
          "Partner with key client and internal stakeholders through strong collaboration and discovery skills, impactful project execution and delivery",
      },
      {
        content:
          "Build an in-depth understanding of clients’ businesses and industries",
      },
      {
        content:
          "Partner effectively with internal teams and external vendors to manage Insights & Analytics design, execution, analysis and delivery on a day-to-day basis",
      },
      {
        content:
          "Support to educate the wider business on the value of Insights & Analytics, and the key role it plays in justifying strategic decision-making.",
      },
      {
        content:
          "Act as a mentor/coach to junior team members to deliver best-in-class impact through our Analytics and Brand Research tools and consultancy capabilities",
      },
      {
        content:
          "Create and own team initiatives that define and strengthen our culture",
      },
    ],
  },
  {
    id: 3,
    heading: "New Business Development",
    data: [
      {
        content:
          "Support VPs and Directors to develop proposals, pitch materials, and articulate scopes of work to secure new business",
      },
      {
        content:
          "Identify up/cross-sell, business development opportunities with existing clients",
      },
      {
        content:
          "Act as an advocate and build team credentials through building case studies and inspirational content to demonstrate team capabilities",
      },
    ],
  },
];
const PersonalTraits = {
  heading: "Key Personal Traits:",
  data: [
    {
      content:
        "a committed and visionary team player with analytical and logical mind-set (you can solve problems like nobody’s business)",
    },
    {
      content:
        "well organized with an innate sense of how to prioritize across a complex environment",
    },
    {
      content:
        "a hands-on person with natural instincts to support our customers",
    },
    {
      content:
        "curious, collaborative and constantly interested in learning new things",
    },
    {
      content: "able to work with multi-disciplinary teams",
    },
    {
      content: "entrepreneurial in your approach",
    },
  ],
};

const data = [
  {
    text: 'Home',
    url: '/'
  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]

function SeniorManager() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div id="homePage" className="openPosition-box">
      <Navbar />
      <GoogleAnalytics/>

      <HeaderSection text1="Senior Manager" picture={banner} button={true} />

      <div className="position-main">
        <div className="position-body">
        <div className="new-box-seventy">

        <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex'}}>
            <a href={ele.url}className="content" key={index}>
              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'white'}}>/</div>}
            </div>
          ))}
        </div>

          <div className="top">
            <div className="heading">
              <span>
                <b>Job description</b>
              </span>
            </div>
            <div className="position">
              <span>Position: Senior Manager / Director</span>
            </div>
            <div className="desired">
              <span>Desired experience: 8-12 years</span>
            </div>
          </div>
          <hr
            style={{
              height: "5px",
              backgroundColor: "#bb1ccc",
              border: "none",
            }}
          />
          <div className="heading1">
            <div className="heading">
              <span>
                <b>The Role</b>
              </span>
            </div>
            <div className="content">
              An exciting opportunity to join fastest growing data analytics
              company solving some of the most complex strategic finance
              problems faced by large and fast growing companies in the world
              utilising big data, cloud computing, AI/ML and most importantly
              smart people. <br />
              <br /> You will be part of Aays’s Center of Excellence (COE) team
              as a Associate Vice President - Analytics partnering with VPs and
              Directors to win new business, deliver finance transformation
              projects and build strategic capabilities for the company. <br />
              <br /> You will be also working closely with the CEO, COO on a
              need basis for multiple use cases and essentially grow up to a
              leadership role handling and initiating sales conversation for mid
              to large accounts. <br />
              <br /> You will have to readily adapt to evolving requirements and
              new technologies and be passionate about creatively demonstrating
              ideas and industry best practices with the highest standards of
              customer service. You must be proactive in challenging a brief,
              ideas and provide high quality presentations with a focus on
              client satisfaction.
              <br />
              <br /> Aays is an employee first company – we firmly believe that
              happy employees can create happy customers and that can in turn
              result in a successful business. Come and join us for a life
              changing journey. The role offers the opportunity to work from
              home remotely with our in-house teams based throughout the UK and
              India.
            </div>
          </div>

          <div className="heading2-list">
            <div className="heading">
              <span>{expertise.heading}</span>
            </div>
            <div className="list">
              <ul>
                {expertise.data.map((item) => (
                  <li>{item.content}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="heading">
            <span>
              <b>Key Responsibilities</b>
            </span>
          </div>

          <div className="heading2-list">
            {Responsibilities.map((item) => (
              <div
                style={{
                  width: "100%",
                  display: "block",
                  margin: "auto",
                }}
              >
                <div className="heading">
                  <span className="subheading">{item.heading}</span>
                </div>
                <div className="list">
                  <ul>
                    {item.data.map((item1) => (
                      <li>{item1.content}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>

          <div className="heading2-list">
            <div className="heading">
              <span>{PersonalTraits.heading}</span>
            </div>
            <div className="list">
              <ul>
                {PersonalTraits.data.map((item) => (
                  <li>{item.content}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
          </div>
          </div>
        </div>
      </div>

      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Full Stack Developer"/>

      <Footer />
    </div>
  );
}

export default SeniorManager;
