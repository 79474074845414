import React from "react";
import PositionReuse from "./PositionReuse";
import Carousel from "react-multi-carousel";

const PositonData = [
  {
    id: 1,
    position: "Data Engineer",
    positionDescription: "Senior Data Engineer / Data Engineer",
    experience: "3-8",
    description:
      "You will act as a key member of data consulting team and build seamless data pipelines for impactful analytics.",
    url: "/careers/data-engineer-job",
  },
  {
    id: 2,
    position: "Data Architect",
    positionDescription: " Data Engineer / Data Architect",
    experience: "6+",
    description:
      "You will craft scalable data systems, contributing to a collaborative and innovative workspace at Aays.",
    url: "/careers/data-architect-role",
  },
  {
    id: 3,
    position: "Full Stack Developer",
    positionDescription: "Full Stack Developer",
    experience: "1-4",
    description:
      "You will act as a key member in our R&D team developing our enterprise analytics product in a collaborative environment.",
    url: "/positions/full-stack-developer",
  },
  {
    id: 4,
    position: "Senior Manager / Director",
    positionDescription: "Senior Manager / Director",
    experience: "8-12",
    description:
      "You will be part of Aays’ Centre of Excellence (COE) team partnering with VPs and Directors for strategic initiatives.",
    url: "/positions/senior-manager",
  },
  {
    id: 5,
    position: " Data Scientist",
    positionDescription: "Data Scientist",
    experience: " 3-8 ",
    description:
      "You will collaborate with global teams and drive impactful solutions by leveraging advanced analytics, machine learning, and big data techniques.  ",
    url: "/careers/data-scientist-job",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Section5( {explore}) {
  return (
    <div className="career-section5-new padding-both" id="exploreOpportunities">
      <div className="main">
        <div className="heading">
          <span>{explore}</span>
        </div>

        <Carousel
          className="customer-success-main"
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          arrows={false}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          infinite={true}
          slidesToSlide={1}
          swipeable={true}
          draggable={false}
          showDots={true}
        >
          {PositonData.map((data) => (
            <PositionReuse
              title={data.position}
              content={data.description}
              positionDescription={data.positionDescription}
              experience={data.experience}
              link={data.url}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Section5;
