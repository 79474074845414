import React, {useState} from "react";
import "./openPosition.scss";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {SchemaDataSciencejob } from "../BlogSchema";
import { Helmet } from "react-helmet";
import HomePageSection5 from "../../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../../pageLayout/AboutPageLayout/Section5";
import { CustomerSuccessStoriesData } from "../../constant/CustomerSuccessStoriesData";
import { useMediaQuery } from "@mui/material";
import Section5 from "../../pageLayout/CareerPageLayout/Section5";
import JoinScientist from "./JoinScientist";
import TeamSpeak from "./TeamSpeak";
import RecentssPost from "./RecentssPost";
import  Newsectionmobile from "../../pageLayout/CustomerSuccessStoryLayout/Newsectionmobile"
import greatplace from "../../assets/img/GrtPlcetoWork.png"
import AaysFinancial from "../../assets/img/Aays_Financial Times_Statista .Square.jpg"
import Dataengin from "../../assets/img/Data Engineering.square.jpg";
import Leadersintop from "../../assets/img/Leadersintop.png"
import Section2Mobile from "../../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";
import BlogsFinancialtimess from "../../assets/img/BlogsFinancialtimess.png";



const SupplyChainDat = [
    {
      id: 1,
      heading: "Aays is now Great Place to Work Certified",
      //content:
        //"Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
      redirectURL:"https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified",
      image: greatplace,
    },
    {
      id: 2,
      heading: "Aays Listed in 500 High Growth Companies Asia Pacific 2024 by Financial Times",
      //content:
        //"The Next Era of Supply Chain Risk Management with AI Intelligence",
      redirectURL: "https://blog.aaysanalytics.com/post/aays-high-growth-companies-asia-pacific-2024",
      image: BlogsFinancialtimess ,
    },
    {
      id: 3,
      heading: "Aays Recognized as one of the Leaders in Data Engineering PeMa",
     // content:
       // "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
      redirectURL: "https://blog.aaysanalytics.com/post/aim-ranks-aays-a-leader-in-data-engineering-service-providers",
      image:Leadersintop,
    },
   
    
  ];





const Data = [
  {
    id: 1,
    heading: "About the Data Scientist Job Role ",
    data: [
      {
        content:
          "As a Data Scientist at Aays, you will be a vital member of our Data Consulting Team. You will collaborate with interdisciplinary scientists, IT, and engineering professionals to solve critical problems and answer important business questions. This role demands strong communication, organizational skills, and a problem-solving attitude.  "
      }
    ],
  },
 
  {
    id: 2,
    heading: "What You Bring to the Team  ",
    data: [
      {
        content:
          "3+ years of experience in an analytics role, with exposure to big data solutions and approaches. ",
      },
      {
        content:
          "Subject Matter Expertise in statistics, analytics, big data, data science, machine learning, deep learning, and cloud technologies  ",
      },
      {
        content: "Hands-on experience analyzing large datasets to derive actionable insights. ",
      },
      {
        content: "Proficiency with databases like SQL, Hadoop, and Apache Spark.  ",
      },
      {
        content: "Broad Machine Learning expertise, including algorithm evaluation, preparation, analysis, modeling, and execution.  ",
      },
      {
        content: "Knowledge of traditional statistical models (e.g., regression, classification, time series) and advanced techniques in machine learning, deep learning, and NLP.  ",
      },
      {
        content: "Strong coding skills in Python and R.  ",
      },
      {
        content: "Experience building analytical and data science solution proposals.  ",
      },
      {
        content: "Exceptional communication skills to partner effectively with technology, product strategy, and data teams.  ",
      },
      {
        content: "A portfolio showcasing GitHub projects, Kaggle contributions, or similar work.   ",
      },
    ],
  },

  {
    id: 3,
    heading: "Personal Traits for Success:  ",
    data: [
      {
        content:
          "Analytical and logical problem-solving mindset. ",
      },
      {
        content:
          "Well-organized with the ability to prioritize in complex environments.  ",
      },
      {
        content:
          "Autonomous, self-starter with a strong sense of ownership.  ",
      },
      {
        content:
          "Curious, collaborative, and eager to learn.  ",
      },
      {
        content:
          "Fluent in English and comfortable working with a global team.  ",
      },
    ],
  },

  {
    id: 4,
    heading: "Why Choose Aays? ",
    data: [
      {
        content:
          "Collaborate with a world-class team solving complex business problems with advanced analytics ",
      },
      {
        content:
          "Fast-track your career in a dynamic and entrepreneurial environment.  ",
      },
      {
        content: "Enjoy a best-in-industry remuneration package.  ",
      },
    ],
  },

  
  
];

const data = [
  // {
  //   text: 'Home',
  //   url: '/'
  // },
  {
    text: ' View all Jobs ',
    url: '/career'
  }
]


function DataScientist() {
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");
    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });

  return (
    <div id="homePage" className="career-main openPosition-box dataEngMobile case-studies-pages-cls">
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( SchemaDataSciencejob)}
        </script>
         </Helmet>

      <Navbar />
      <GoogleAnalytics/>

    <HeaderSection text1="Data Scientist Job at Aays " picture={banner} button = {true} />

      <div className="position-main">
        <div className="position-body">
          <div className="new-box-seventy">

        <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex',paddingTop:'2rem',paddingBottom:'1rem'}}>
            <a href={ele.url}className="content" key={index}>
            
                 {/* <i class='fa fa-arrow-right'
                 style={{fontSize:'16px',color:'black',marginRight:'3px'}}></i> */}
                 <p style={{marginRight:"3px",color:'white',}}><ChevronLeftIcon /></p>

              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'black'}}>/</div>}
            </div>
          ))}
        </div>

          <div className="top">
            <div className="heading">
              <span>
                <b>Job description</b>

              </span>
            </div>
            <div className="position">
              <span>Position: Data Scientist </span>
            </div>
            <div className="desired">
              <span>Desired experience: 3-8 years</span>
            </div>
          </div>
          <hr
            style={{
              height: "5px",
              backgroundColor: "#bb1ccc",
              border: "none",
              marginBottom:'1rem',
            }}
          />
          <div className="heading1">
            <div className="content">
            You will act as a key member of data consulting team and build seamless data pipelines for impactful analytics.  
            </div>
          </div>
          <div className="heading2-list">
            {Data.map((item) => (
              <>
                <div className="heading">
                  <span>{item.heading}</span>
                </div>
                <div className="list">
                  <ul>
                    {item.data.map((item1) => (
                      <li>{item1.content}</li>
                    ))}
                  </ul>
                </div>
              </>
            ))}
          </div>
           <p style={{color:'white',margin:'3px 0'}}>Join Aays as a Data Scientist and make an impact by driving innovative solutions and creating value through cutting-edge analytics.   </p> 
          <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
          </div>
          </div>
        </div>
      </div>
      <JoinScientist/>
      <div className="career-body">
      <TeamSpeak/>
      </div>
    
      {isMobile ? (
        <div style={{ paddingTop: "2.5rem", backgroundColor: "black" }}>
          <Section2Mobile
            heading="Recent Posts"
            data={SupplyChainDat}
          />
        </div>
      ) : (
        <div className="section" id="caseStudySection2">
          < RecentssPost />
        </div>
      )} 
      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Data Engineer"/>
      
      <Footer />
    </div>
  );
}

export default DataScientist;
