import React from 'react'
import  { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";
import supplyImg1 from "../../assets/img/Supply Chain.png";
import supplyImg2 from "../../assets/img/inventory-case-studies-page-new.png";
import supplyImg4 from "../../assets/img/Innovation Analytics.png";
 import RightArrow from "../../pageLayout/CustomerSuccessStoryLayout/RightArrow";
 import LeftArrow from "../../pageLayout/CustomerSuccessStoryLayout/LeftArrow";
 import Supplychain from "../../assets/img/SupplychainriskAi.png";
 import AiandInventory from "../../assets/img/AIandInventoryMngement.png"
 import Casestudyinventoanalytics from "../../assets/img/Casestudyinventoanalytics.png"
 import StrengtheningSupplyChains from "../../assets/img/StrengtheningSupplyChains.png";





const data = [
    {
        id: 1,
        title1: "Inventory",
        title2: "Analytics",
        content: "Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
        class: "content-box-section2",
        url: "/customer-success-stories/inventory-optimization-global-CPG",
        image: Casestudyinventoanalytics,
      },
      {
        id: 2,
         title1: "Supply Chain ",
         title2: "Risk",
        content: "The Next Era of Supply Chain Risk Management with AI Intelligence",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/the-next-era-of-supply-chain-risk-management-with-ai-intelligence",
        image:Supplychain ,
      },
      {
        id: 3,
        title1: "AI and ",
         title2: "Inventory",
        content: "Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge",
        class: "content-box-section2 inventrynew",
        url: "https://blog.aaysanalytics.com/post/ai-inventory-management",
        image:  AiandInventory,
      },
    
      {
        id: 4,
        title1: "Supply",
        title2: "Chain",
        content: "How a global CPG conglomerate strengthened its supply chain with optimised supply planning and improved AI-enabled demand forecasting",
        class: "content-box-section2",
        url: "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
        image:  StrengtheningSupplyChains ,
      },
  ];

const PostRecents = () => {
    const [startIndex, setStartIndex] = useState(0);

    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });
  
    const showLength = isIpad ? 2 : 3;
    const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);
  
    return (
      <div
        id="section2-customer-success-story"
        className="customer-success-section2 scroll-section"
        style={{position: 'relative',backgroundColor:'white'}}
      >
        <div className="customer-section2-body margin-box">
          <h2 className="success-story-page-section-2-Header-text" style={{color:'black'}}>
          Recent Posts 
          </h2>
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
            <div className="success-story-page-section-2-grid">
              {visibleItems.map((item) => (
                <div>
                  <CustomerSectionBox
                    picture={item.image}
                    title1={item.title1}
                    title2={item.title2}
                    content={item?.content}
                    class={item.class}
                    redirectURL={item.url}
                  />
                </div>
              ))}
            </div>
            {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          </div>
        </div>
      </div>
    );
  }
  

export default PostRecents