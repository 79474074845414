import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import ai1 from "../../assets/img/ai1.jpg";
import ai2 from "../../assets/img/ai2.png";
import ai3 from "../../assets/img/ai3.png";
import ai54 from "../../assets/img/Blog-Img-Square.png"
import SupplychainriskAi from "../../assets/img/SupplychainriskAi.png";
import ai6 from "../../assets/img/Blogs-ai-trasn.png";
import DemndFrcasting from  "../../assets/img/DemndFrcasting.png";
import AIandInventoryMngement from "../../assets/img/AIandInventoryMngement.png";
import Analytics from "../../assets/img/Analytics-reliable-chain.png"
import Transformingcpg from "../../assets/img/Transforming-cpg-pricing.png"
import Optimise from "../../assets/img/Optimise-chain-cost.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
  },
  superdesktop: {
    breakpoint: { max: 1296, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};



const Section3 = () => {
 

  return (
    <div
    id="supplychainss"
    className="blog-success-section2 scroll-section"
  >
    <div className="customer-section2-body margin-box">
      <div className="success-story-page-section-2-Header-text">
      Supply Chain & CPG
      </div>
      <div className="customer-section2-body">
        <div className="section2-box">
          {/* <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          className="new-cls-blogs"
        > */}

          <Carousel
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            infinite={true}
            slidesToSlide={1}
            swipeable={true}
            draggable={false}
            // showDots={isMobile? false : true}
          >
            {/* <CustomerSectionBox
              picture={ai7}
              title1="Transforming Inventory Management with AI"
              date="Sep 26, 2024 "
              read="2 min read"
              class="content-box-section2a"
              redirectURL="/blogs/business-analytics-architecture"
            /> */}
            <CustomerSectionBox
              picture={SupplychainriskAi}
              title1="The Next Era of Supply Chain Risk Management with AI Intelligence"
              date="Dec 19, 2024"
              class="content-box-section2a"
              read=" 2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/the-next-era-of-supply-chain-risk-management-with-ai-intelligence"
            />
             <CustomerSectionBox
              picture={DemndFrcasting}
              title1="How AI is Reshaping the Future of Demand Forecasting"
              date="Dec 3, 2024"
              class="content-box-section2a"
              read=" 2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/ai-demand-forecasting"
            />
            <CustomerSectionBox
              picture={AIandInventoryMngement}
              title1="Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge"
              date="Sep 11, 2024"
              class="content-box-section2a"
              read=" 2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/ai-inventory-management"
            />
            <CustomerSectionBox
              picture={ai54}
              title1="The Future of Supply Chain Management: How AI is Leading the Way?"
              date="Sep 13, 2024"
              class="content-box-section2a"
              read=" 2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/the-future-of-supply-chain-management-how-ai-is-leading-the-way"
            />
            <CustomerSectionBox
              picture={Transformingcpg }
              title1="Increase Sales with CPG Pricing Analytics: A 5-10% Boost Opportunity"
              date="Aug 8, 2023"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/transforming-cpg-pricing-analytics"
            />

            <CustomerSectionBox
              picture={Optimise}
              title1=" Supply Chain Cost Reduction in CPG Firms through Data-Driven Optimization"
              date="May 4, 2023"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/cpg-supply-chain-cost-reduction"
            />
              <CustomerSectionBox
              picture={Analytics}
              title1="Enhancing Supply Chain Management with Analytics: Cost Reduction and Efficiency"
              date="April 13, 2023 "
              class="content-box-section2a"
              read="2 min read"
              redirectURL="https://blog.aaysanalytics.com/post/analytics-in-supply-chain-management"
            />
          </Carousel>
          {/* </div> */}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Section3;
