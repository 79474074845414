import React, {useState} from "react";
import "./openPosition.scss";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SchemaDataEngneeringsection } from "../BlogSchema";
import { Helmet } from "react-helmet";
import HomePageSection5 from "../../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../../pageLayout/AboutPageLayout/Section5";
import { CustomerSuccessStoriesData } from "../../constant/CustomerSuccessStoriesData";
import { useMediaQuery } from "@mui/material";
import Section5 from "../../pageLayout/CareerPageLayout/Section5";
import Meetexperts from "./Meetexperts";






const Data = [
  {
    id: 1,
    heading: "About the Data Engineer Job ",
    data: [
      {
        content:
          "You will act as a key member of the Data Consulting Team, working directly with partners and senior stakeholders to design and implement big data and analytics solutions. This role demands exceptional communication and organizational skills, along with a problem-solving attitude. "
      }
    ],
  },
 
  {
    id: 2,
    heading: "Why Join Aays? ",
    data: [
      {
        content:
          "Work with a world-class team of business consultants and engineers solving complex business challenges using data and analytics techniques.",
      },
      {
        content:
          "Fast-track your career in a highly entrepreneurial work environment.  ",
      },
      {
        content: "Enjoy a best-in-industry remuneration package. ",
      },
    ],
  },

  {
    id: 3,
    heading: "Essential Technical Skills for the Data Engineer Job ",
    data: [
      {
        content:
          "Proficiency with Big Data technologies, such as Python, Spark, Hadoop, Clojure, Git, SQL, and Databricks.",
      },
      {
        content:
          "Experience with visualization tools like Tableau and Power BI.  ",
      },
      {
        content:
          "Expertise in cloud, container, and microservices infrastructures. ",
      },
      {
        content:
          "Hands-on experience with data modeling, query techniques, and complexity analysis. ",
      },
    ],
  },

  {
    id: 4,
    heading: "Desirable Skills",
    data: [
      {
        content:
          "Familiarity with agile methodologies, such as Scrum. ",
      },
      {
        content:
          "Experience collaborating with development teams and product owners to understand requirements.  ",
      },
      {
        content: "Certifications in any of the relevant technical areas are preferred. ",
      },
    ],
  },

  {
    id: 5,
    heading: "Key Responsibilities ",
    data: [
      {
        content:
          "Develop data solutions within Big Data Azure and other cloud environments.  ",
      },
      {
        content:
          "Work with divergent data sets to support Data Science and Analytics teams. ",
      },
      {
        content:
          "Build and design data architectures using Azure Data Factory, Databricks, Data Lake, and Synapse. ",
      },
      {
        content:
          "Collaborate with CTO, Product Owners, and Operations Teams to deliver engineering roadmaps ",
      },
      {
        content:
          "Perform data mapping activities for source-to-target transformations. ",
      },
      {
        content:
          "Assist Data Analysts in developing KPIs and creating reports using Power BI and Tableau.",
      },
      {
        content: "Manage data integration, transformation, and modeling activities. ",
      },
      {
        content: "Maintain comprehensive documentation and knowledge bases. ",
      },
      {
        content:
          "Research and recommend new database products, services, and protocols. ",
      },
    ],
  },
  {
    id: 6,
    heading: "Personal Traits for Success ",
    data: [
      {
        content:
          "Ability to work independently and communicate effectively with remote teams.  ",
      },
      {
        content:
          "Timely communication and escalation of issues or dependencies to management. ",
      },
      {
        content:
          "Curiosity to learn and apply emerging technologies to solve business problems. ",
      },
    ],
  },
];

const data = [
  // {
  //   text: 'Home',
  //   url: '/'
  // },
  {
    text: ' View all Jobs ',
    url: '/career'
  }
]


function DataEngineer() {
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery("(max-width: 767px)");
    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
      defaultMatches: false, // Set defaultMatches to false
    });

  return (
    <div id="homePage" className="openPosition-box dataEngMobile career-main">
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify( SchemaDataEngneeringsection)}
        </script>
         </Helmet>

      <Navbar />
      <GoogleAnalytics/>

   <HeaderSection text1="Data Engineer Job at Aays  " picture={banner} button = {true}  /> 

      <div className="position-main">
        <div className="position-body">
          <div className="new-box-seventy">

        <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex',paddingTop:'2rem',paddingBottom:'1rem'}}>
            <a href={ele.url}className="content" key={index}>
            
                 {/* <i class='fa fa-arrow-right'
                 style={{fontSize:'16px',color:'black',marginRight:'3px'}}></i> */}
                 <p style={{marginRight:"3px",color:'white',}}><ChevronLeftIcon /></p>

              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'black'}}>/</div>}
            </div>
          ))}
        </div>

          <div className="top">
            <div className="heading">
              <h4>
              <span>
                <b>Job description</b>

              </span>
              </h4>
            </div>
            <div className="position">
             <h4><span>Position: Senior Data Engineer / Data Engineer</span></h4> 
            </div>
            <div className="desired">
              <h4>
              <span>Desired experience: 3-8 years</span>
              </h4>
            </div>
          </div>
          <hr
            style={{
              height: "5px",
              backgroundColor: "#bb1ccc",
              border: "none",
              marginBottom:'1rem',
            }}
          />
          <div className="heading1">
            <div className="content">
            You will act as a key member of data consulting team and build seamless data pipelines for impactful analytics.  
            </div>
          </div>
          <div className="heading2-list">
            {Data.map((item) => (
              <>
                <div className="heading">
                  {/* <span>{item.heading}</span> */}
                  <h2><span>{item.heading}</span></h2>
                </div>
                <div className="list">
                  <ul>
                    {item.data.map((item1) => (
                      <li>{item1.content}</li>
                    ))}
                  </ul>
                </div>
              </>
            ))}
          </div>
          <p style={{color:'white',margin:'3px 0'}}>Take the next step in your career by joining Aays as a Data Engineer. Make an impact by solving challenging problems and working on cutting-edge technologies.  </p>
          <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
          </div>
          </div>
        </div>
      </div>
      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Data Engineer"/>
      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="data engineering"
            show="false"
            heading1=" Data Engineering" 
  heading2=" Success Stories "
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="data engineering"
            show="false"
            heading="Success Stories" 
 
          />
        )}
      </div>
      <Meetexperts/>
      <div className="career-body">
     <Section5  explore="Explore Other Openings" />
     </div>
      <Footer />
    </div>
  );
}

export default DataEngineer;
