import React from "react";
import { useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image from "../../assets/img/homePage-customerSuccess-image.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function HomePageSection5(props) {
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const visibility = props.show || "true";

  const dataFilter = props.data;
  const tag = props.tag;

  const filteredData = dataFilter.filter((item) => item.tags.includes(tag));

  return (
    <div className="homepage-section4-main">
      <div className="homepage-section5">
        <div
          className="main "
          style={{
            padding: "1rem",
          }}
        >
          {isIpad && (
            <h2
              style={{
                fontSize: "35px",
                textAlign: "center",
                padding: "1rem",
                borderBottom: "4px solid white",
              }}
            >
             {props.heading || "Customer Success Stories"} 
            </h2>
          )}
          {!isIpad && (
            <h2
              style={{
                fontSize: "20px",
                textAlign: "center",
                padding: "0.5rem",
                borderBottom: "2px solid white",
              }}
            >
             {props.heading || "Customer Success Stories"} 
            </h2>
          )}
          <div className="grid-container">
            <div className="left">
              <img loading="lazy" src={image} alt="" />
            </div>
            <div className="right">
              <Carousel
                className="customer-success-main"
                responsive={responsive}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                //   removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                infinite={true}
                slidesToSlide={1}
                swipeable={true}
                draggable={false}
                showDots={isIpad ? true : false}
              >
                {filteredData.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="box">
                        <h2
                          className="box-heading"
                          style={{
                            color: "#bb1ccc",
                            textAlign: "center",
                            padding: "10px 0px",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            fontSize: isMobile ? "18px" : "20px",
                          }}
                        >
                          {data.subHeaderText}
                        </h2>

                        <p
                          style={{
                            fontSize: isMobile ? "16px" : "18px",
                            textAlign: "center",
                          }}
                          div
                          className="box-content"
                        >
                          {data.content}
                        </p>

                        {visibility === "true" && (
                          <a href={`${data.redirectUrl}`}>
                            <div className="btn-read-more">
                              <button style={{ cursor: "pointer" }}>
                                Read More
                              </button>
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageSection5;
